import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Badge from "reactstrap/lib/Badge";

import "./MachineStream.scss";

function MachineStream({ location, forwarder, channel, deviceIp, isActive }) {
  return (
    <div className="machine-stream">
      <Row>
        <Col>
          <Link to={`/stores/${location.id}`}>
            <span className="location">
              <i className="fas fa-store-alt mr-2" />
              {location.name}
            </span>
          </Link>
        </Col>
        <Col>
          <span>
            {forwarder && (
              <Badge color="forwarder">
                <i className="fas fa-arrows-alt-h mr-2" />
                {forwarder}
              </Badge>
            )}
            {!forwarder && <Badge color="secondary">LOCAL</Badge>}
          </span>
        </Col>
        <Col>
          <span>
            <Badge color="dark">
              <i className="fas fa-video mr-2" />
              {channel}
            </Badge>
          </span>
        </Col>
        <Col>
          <span>
            <Badge color="dark">
              <i className="fa fa-wifi" />
              {deviceIp}
            </Badge>
          </span>
        </Col>
        <Col>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              color: isActive ? "green" : "red",
            }}
          >
            <i className={isActive ? "fas fa-check-circle" : "fas fa-times-circle"} />
            {isActive ? "Active" : "Inactive"}
          </span>
        </Col>
      </Row>
    </div>
  );
}

export default MachineStream;

import React, { useState } from "react";
import { toast } from "react-toastify";

import { Card, CardHeader, CardBody, Button, Col, Input, InputGroup, InputGroupAddon } from "reactstrap";
import ServerServices from "./ServerServices";
import DisplayWhen from "./common/base/DisplayWhen";
import userHasRole from "./shared/utils/authUtils";
import { technicianRole, adminEditorRole, csmRole, csmMaintenanceRole, techopsRole } from "./shared/constants";
import { createCloudInstance } from "../services/machineService";
import { restartServerContainer } from "../services/locationService";

function ServerServicesList({ servers, store }) {
  const [podname, setPodname] = useState("");
  const createPod = () => {
    createCloudInstance()
      .then((response) => {
        toast.success(`Sucessfully created instance: ${response.data.hostname}`);
        setPodname(response.data.hostname);
      })
      .catch((error) => {
        if (error.response && error.response.status < 500) {
          toast.error(error.response.data.detail || "An error occurred while creating server");
        }
        console.error("Error generating cloud server:", error);
      });
  };
  const restartCloudInference = (server) => {
    restartServerContainer(server, "simone-inference")
      .then(() => {
        toast.success(`Sucessfully restarted : ${server}`);
      })
      .catch((error) => {
        if (error.response && error.response.status < 500) {
          toast.error(error.response.data.detail || "An error occurred while restarting server");
        }
        console.error("Error restarting cloud server:", error);
      });
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to clipboard!");
    });
  };
  return (
    <Card>
      <CardHeader className="mb-2">
        <h5 className="card-category">Servers</h5>
      </CardHeader>

      {Object.keys(servers.status).map((server, index) => (
        <CardBody key={`server-${index}`}>
          <h4>
            {server}
            <DisplayWhen
              condition={servers.status[server].id && (!userHasRole(csmRole) || userHasRole(csmMaintenanceRole))}
            >
              <a className="ml-3" href={`/remotecontrol?server=${servers.status[server].id}&command=screenshot`}>
                <Button>Remote Control</Button>
              </a>
              <a
                className="ml-3"
                href={`https://grafana.prod.veesion.io/d/f3c2ed1d-0329-4d8e-b972-f3f305c17478/server-overview?orgId=1&var-node=${server}&from=now-24h&to=now`}
                target="_blank" // popup
                rel="noopener noreferrer"
              >
                <Button>Server Overview</Button>
              </a>
            </DisplayWhen>
            <DisplayWhen condition={!store.local_inference}>
              <Button className="ml-3 btn-primary" onClick={() => restartCloudInference(server)}>
                <i className="fas fa-power-off mr-2" /> Restart
              </Button>
            </DisplayWhen>
          </h4>
          <DisplayWhen condition={store.local_inference}>
            <ServerServices server={servers.status[server]} serverName={server} />
          </DisplayWhen>
        </CardBody>
      ))}

      <DisplayWhen
        condition={
          !store.local_inference &&
          (userHasRole(technicianRole) || userHasRole(adminEditorRole) || userHasRole(techopsRole))
        }
      >
        <Col md={12}>
          <Button className="float-left" onClick={() => createPod()}>
            Create Cloud Server
          </Button>
          <InputGroup>
            <Input type="text" value={podname} />
            <InputGroupAddon addonType="append">
              <Button color="secondary" onClick={() => copyToClipboard(podname)}>
                <i className="fas fa-copy" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </DisplayWhen>
    </Card>
  );
}

export default ServerServicesList;

import Cookies from "js-cookie";

import http from "./httpService";
import * as Sentry from "@sentry/react";
import AxiosFactory from "../components/shared/network/axios-factory";

export const apiTokenKey = "SESSION_TOKEN";
export const currentUserKey = "ME";

/**
 * Get access_token from keycloak and uses it to retrieve a session token.
 *
 * @param authorizationCode
 * @returns {Promise<void>}
 */
export async function getAccessToken(authorizationCode) {
  const axiosInstance = AxiosFactory.newInstance();

  try {
    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("code", authorizationCode);
    params.append("client_id", process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
    params.append("redirect_uri", process.env.REACT_APP_KEYCLOAK_REDIRECT_URI);

    const { data: token } = await axiosInstance.post(process.env.REACT_APP_KEYCLOAK_TOKEN_ENDPOINT, params);
    const sessionToken = await getSessionToken(token.access_token);
    await closeUserSession(token.refresh_token);
    return sessionToken;
  } catch (e) {
    /* continue regardless of error */
  }

  return null;
}

/**
 * Ends the user session on keycloak.
 *
 * @param refreshToken refresh token to revoke.
 * @returns {Promise<void>}
 */
async function closeUserSession(refreshToken) {
  const axiosInstance = AxiosFactory.newInstance();

  try {
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
    params.append("refresh_token", refreshToken);

    await axiosInstance.post(process.env.REACT_APP_KEYCLOAK_END_SESSION_ENDPOINT, params, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
  } catch (e) {
    /* continue regardless of error */
  }
}

/**
 * Get session token from access token.
 *
 * @param accessToken access token.
 * @returns {Promise<void>}
 */
async function getSessionToken(accessToken) {
  const axiosInstance = AxiosFactory.newInstance();

  try {
    const { data: apiToken } = await axiosInstance.post("/auth/login/", null, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const expiry = new Date(apiToken.expiry);

    const cookiesOptions = {
      expires: expiry,
      sameSite: "none",
      secure: true,
    };

    Cookies.set(apiTokenKey, apiToken.token, cookiesOptions);
    Cookies.set(currentUserKey, JSON.stringify(apiToken.user), cookiesOptions);
    Sentry.setUser({ username: apiToken.token.substring(0, 9) });

    return apiToken.token;
  } catch (e) {
    logout();
  }

  return null;
}

export function isAuthenticated() {
  return !!Cookies.get(apiTokenKey);
}

export function revokeSessionToken() {
  http.post("/auth/logout/").catch(console.error);
}

export function getCurrentUser() {
  if (!isAuthenticated()) {
    return null;
  }

  return JSON.parse(Cookies.get(currentUserKey));
}

export function logout() {
  revokeSessionToken();
  Cookies.remove(apiTokenKey);
}

const auth = {
  getAccessToken,
  getCurrentUser,
  isAuthenticated,
  logout,
};

export default auth;

import React, { Component } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, CardHeader, CardTitle, Col, InputGroup, Row, Spinner } from "reactstrap";
import triggerRemoteCommand from "../../services/remoteControlService";
import PingDeviceForm from "../Forms/remotecontrol/PingDeviceForm";
import ScanNetworkForm from "../Forms/remotecontrol/ScanNetworkForm";
import ConnectDvrInterfaceForm from "../Forms/remotecontrol/ConnectDvrInterfaceForm";
import ScreenshotForm from "../Forms/remotecontrol/ScreenshotForm";
import ArpScanForm from "../Forms/remotecontrol/ArpScanForm";
import NetworkCheckForm from "../Forms/remotecontrol/NetworkCheckForm";
import NmapForm from "../Forms/remotecontrol/NmapForm";
import UpdatedvrForm from "../Forms/remotecontrol/UpdatedvrForm";
import DiscoverForm from "../Forms/remotecontrol/DiscoverForm";
import StatusForm from "../Forms/remotecontrol/StatusForm";
import ResetForm from "../Forms/remotecontrol/ResetForm";
import SetupForm from "../Forms/remotecontrol/SetupForm";
import HardwareSpecificationsForm from "../Forms/remotecontrol/HardwareSpecificationsForm";
import PingDeviceResult from "./PingDeviceResult";
import ArpScanResult from "./ArpScanResult";
import NmapResult from "./NmapResult";
import UpdatedvrResult from "./UpdatedvrResult";
import ScanNetworkResult from "./ScanNetworkResult";
import ConnectDvrInterfaceResult from "./ConnectDvrInterfaceResult";
import ScreenshotResult from "./ScreenshotResult";
import NetworkCheckResult from "./NetworkCheckResult";
import DiscoverResult from "./DiscoverResult";
import StatusResult from "./StatusResult";
import ResetResult from "./ResetResult";
import SetupResult from "./SetupResult";
import HardwareSpecificationsResult from "./HardwareSpecificationsResult";
import { getHardwareSpecificationsByHostname } from "../../services/serversService";

class Command extends Component {
  state = {
    server: null,
    command: null,
    stdErr: null,
    stdOut: null,
    status: null,
    running: false,
    hardwareSpecs: null,
    onRunningCommand: () => {},
  };

  componentDidMount() {
    const { command, server, handleRunCommand } = this.props;
    this.setState({
      command,
      server,
      onRunningCommand: handleRunCommand,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.command !== nextProps.command) {
      return {
        command: nextProps.command,
        server: nextProps.server,
        status: null,
        stdErr: null,
        stdOut: null,
        hardwareSpecs: null,
      };
    }
    return {
      command: nextProps.command,
      server: nextProps.server,
    };
  }

  handleFormSubmit = async (formData) => {
    const { running } = this.state;
    if (running) {
      toast.warn("A command is already running");
    } else {
      await this.executeCommand(formData);
    }
  };

  handleHardwareSpecSubmit = async () => {
    const { server } = this.state;
    try {
      const response = await getHardwareSpecificationsByHostname(server.hostname);
      this.setState({ hardwareSpecs: response.data });
    } catch (error) {
      toast.error("Failed to fetch hardware specifications");
    }
  };

  async executeCommand(formData) {
    const { server, command, onRunningCommand } = this.state;
    this.setState({ hardwareSpecs: null });

    const data = {};
    data.hostname = server.hostname;
    data.cmd = command.label;
    data.cmdArgs = formData;

    let running = true;
    onRunningCommand(running);
    this.setState({
      status: null,
      running,
      stdErr: null,
      stdOut: null,
    });
    toast.info("Running command, please wait...");
    await triggerRemoteCommand(data)
      .then((response) => {
        if (response.status === "OK") {
          toast.success("Command succeded !");
        } else if (response.status === "FAILED") {
          toast.error("Command failed...");
        } else if (response.status === "EXPIRED") {
          toast.error("Command took too long, interrupted");
        }
        running = false;
        onRunningCommand(running);
        this.setState({
          stdErr: response.stderr,
          stdOut: response.stdout,
          status: response.status,
          running,
        });
      })
      .catch(() => {
        running = false;
        onRunningCommand(running);
        this.setState({
          running,
        });
        toast.error("Unhandled error");
      });
  }

  render() {
    const { command, status, server, running, stdOut, stdErr, hardwareSpecs } = this.state;
    const { videoRecorder, isDisabledButton } = this.props;

    return (
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4" style={{ textTransform: "none" }}>
                {command.label} parameters
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <InputGroup>
                    {(() => {
                      switch (command.label) {
                        case "ping":
                          return <PingDeviceForm handleSubmit={this.handleFormSubmit} />;
                        case "screenshot":
                          return (
                            <ScreenshotForm
                              handleSubmit={this.handleFormSubmit}
                              videoRecorder={videoRecorder}
                              isDisabledButton={isDisabledButton}
                            />
                          );
                        case "arp-scan":
                          return <ArpScanForm server={server} handleSubmit={this.handleFormSubmit} />;
                        case "scan":
                          return <ScanNetworkForm handleSubmit={this.handleFormSubmit} />;
                        case "connect-dvr-interface":
                          return (
                            <ConnectDvrInterfaceForm
                              handleSubmit={this.handleFormSubmit}
                              videoRecorder={videoRecorder}
                            />
                          );
                        case "network-check":
                          return <NetworkCheckForm handleSubmit={this.handleFormSubmit} />;
                        case "nmap":
                          return <NmapForm handleSubmit={this.handleFormSubmit} />;
                        case "update-dvr":
                          return <UpdatedvrForm handleSubmit={this.handleFormSubmit} videoRecorder={videoRecorder} />;
                        case "discover":
                          return <DiscoverForm handleSubmit={this.handleFormSubmit} hostname={server.hostname} />;
                        case "status":
                          return <StatusForm handleSubmit={this.handleFormSubmit} />;
                        case "reset":
                          return <ResetForm handleSubmit={this.handleFormSubmit} />;
                        case "setup":
                          return <SetupForm handleSubmit={this.handleFormSubmit} />;
                        case "hardware-specifications":
                          return <HardwareSpecificationsForm handleSubmit={this.handleHardwareSpecSubmit} />;
                        default:
                          return null;
                      }
                    })()}
                  </InputGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {running && <Spinner color="primary" />}
          {command.label === "hardware-specifications" && hardwareSpecs && (
            <HardwareSpecificationsResult specifications={hardwareSpecs} />
          )}
          {status &&
            (() => {
              switch (command.label) {
                case "ping":
                  return <PingDeviceResult stdOut={stdOut} stdErr={stdErr} status={status} />;
                case "screenshot":
                  return <ScreenshotResult stdOut={stdOut} stdErr={stdErr} status={status} />;
                case "arp-scan":
                  return <ArpScanResult stdOut={stdOut} stdErr={stdErr} status={status} />;
                case "scan":
                  return <ScanNetworkResult stdOut={stdOut} stdErr={stdErr} status={status} />;
                case "connect-dvr-interface":
                  return <ConnectDvrInterfaceResult stdOut={stdOut} stdErr={stdErr} status={status} server={server} />;
                case "network-check":
                  return <NetworkCheckResult stdOut={stdOut} stdErr={stdErr} status={status} />;
                case "nmap":
                  return <NmapResult stdOut={stdOut} stdErr={stdErr} status={status} />;
                case "update-dvr":
                  return <UpdatedvrResult stdOut={stdOut} stdErr={stdErr} status={status} />;
                case "discover":
                  return <DiscoverResult stdOut={stdOut} stdErr={stdErr} status={status} />;
                case "status":
                  return <StatusResult stdOut={stdOut} stdErr={stdErr} status={status} />;
                case "reset":
                  return <ResetResult stdOut={stdOut || ""} stdErr={stdErr || ""} status={status || "UNKNOWN"} />;
                case "setup":
                  return <SetupResult stdOut={stdOut} stdErr={stdErr} status={status} />;
                case "hardware-specifications":
                  return <HardwareSpecificationsResult stdOut={stdOut} stdErr={stdErr} status={status} />;
                default:
                  return null;
              }
            })()}
        </Col>
      </Row>
    );
  }
}

export default Command;

import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Badge } from "reactstrap";

class ResetResult extends Component {
  state = {
    stdOut: "",
    stdErr: "",
    status: "",
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      stdOut: nextProps.stdOut || "",
      stdErr: nextProps.stdErr || "",
      status: nextProps.status || "",
    };
  }

  render() {
    const { stdOut, stdErr, status } = this.state;

    return (
      <Row>
        <Col md="12">
          <Card className="shadow-sm mb-4">
            <CardHeader>
              <CardTitle className="text-center" tag="h5">
                Reset Result
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="text-center mb-3">
                <h5>
                  Status: <Badge color={status === "SUCCESS" ? "success" : "danger"}>{status}</Badge>
                </h5>
              </div>

              {stdOut && (
                <div>
                  <h6>Process:</h6>
                  <pre>{stdOut}</pre>
                </div>
              )}

              {stdErr && (
                <div>
                  <h6 className="text-danger">Error Output:</h6>
                  <pre className="text-danger">{stdErr}</pre>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ResetResult;

import React from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col, CardFooter, Spinner, Button } from "reactstrap";
import Badge from "reactstrap/lib/Badge";
import MachineStreams from "../components/MachineStreams";
import MachineResellerInfo from "../components/MachineResellerInfo";
import { getInferenceMachine } from "../services/inferenceMachineService";
import "../components/machines/InferenceMachineView.scss";
import SoftwareVersions from "../components/SoftwareVersions";

class InferenceMachineDetailView extends React.Component {
  state = { machine: null };
  async componentDidMount() {
    const { match } = this.props;
    try {
      const { data: machine } = await getInferenceMachine(match.params.id);
      this.setState({ machine });
    } catch (ex) {
      /* continue regardless of error */
    }
  }
  render() {
    const { machine } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader className="mb-2">
                <h5 className="card-category">Inference Machine</h5>
                {machine && (
                  <React.Fragment>
                    <CardTitle tag="h2">{machine.machine.hostname}</CardTitle>
                    <div className="menu-bar">
                      <div>
                        <Badge color="primary">{machine.machine.group}</Badge>
                      </div>
                      {machine.machine.ip && (
                        <a href={`ssh://veesion@${machine.machine.ip}`}>
                          <Button>Connect</Button>
                        </a>
                      )}
                      {!machine.machine.ip && <Button disabled={!machine.machine.ip}>Connect</Button>}
                    </div>
                  </React.Fragment>
                )}
              </CardHeader>
              <CardBody>
                {machine && (
                  <React.Fragment>
                    <MachineResellerInfo machineId={machine.machine.id} hostname={machine.machine.hostname} />
                    <SoftwareVersions hostname={machine.machine.hostname} />
                    <MachineStreams streams={machine.channels} />
                  </React.Fragment>
                )}
                {!machine && <Spinner color="primary" />}
              </CardBody>
              <CardFooter />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default InferenceMachineDetailView;
